/**
 * SPDX-FileCopyrightText: © 2018 Liferay, Inc. <https://liferay.com>
 * SPDX-License-Identifier: BSD-3-Clause
 */

export const DEFAULT_COLORS = [
	'#006eff',
	'#cc4e00',
	'#e60000',
	'#1b7e6e',
	'#e50082',
	'#458613',
	'#aa33ff',
	'#cc4e00',
	'#0077b3',
];

export const DEFAULT_GRID_OBJECT = {
	x: {show: true},
	y: {show: true},
};

export const DEFAULT_LINE_CLASSES = [
	'bb-line-dashed-2-2',
	'bb-line-dashed-2-3',
	'bb-line-dashed-2-4',
	'bb-line-dashed-3-2',
	'bb-line-dashed-3-3',
	'bb-line-dashed-3-4',
	'bb-line-dashed-4-2',
	'bb-line-dashed-4-3',
	'bb-line-dashed-4-4',
];

export const DEFAULT_TILES = [
	'circles',
	'diagonal-left-large',
	'diagonal-left-small',
	'diagonal-right-large',
	'diagonal-right-small',
	'horizontal-large',
	'horizontal-small',
	'vertical-large',
	'vertical-small',
];

export const DEFAULT_POINT_PATTERNS = [
	'circle',
	'rectangle',
	'<polygon points="2.5 0 0 5 5 5"></polygon>',
	'<polygon points="2.5 0 0 2.5 2.5 5 5 2.5"></polygon>',
	'<polygon points="0 0 2.5 5 5 0"></polygon>',
	'<path d="M5,5 a1,1 0 1,1 5,0">',
	'<path d="M0,0 a1,1 0 0,0 5,0">',
	'<rect width="5" height="2.5"></rect>',
	'<rect width="2.5" height="5"></rect>',
];
